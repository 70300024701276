<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    window.companyName = location.pathname.substring(1)
    // window.companyName = 'jinheshun'
  }
}
</script>
<style>
html {
  font-size: 100px;
}

* {
  margin: 0;
  padding: 0;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: PingFangSC-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFBF0;
}

.body {
  width: 100%;
  min-height: 100vh;
}
</style>
